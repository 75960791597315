import formItems from './formItems.js';

export default {
	mixins: [formItems],
	// 订单详情tabs
	data() {
		return {
			recordDetails: '/api/order/invoice/load', // 订单记录详情
			orderLog: '/api/log/userlog_list' // 订单日志
		}
	},
	methods: {
		async getOrderDetailsTabs(item) {
			this.showOperateBtn = false;
			// 订单商品详情
			this.items = [];
			this.dialog = true;
			// 订单详情
			let orderItems = JSON.parse(JSON.stringify(this.orderItems));
			let response1 = await this.axios.post(this.recordDetails, {bookingid: item.bookingid});
			if (response1.code === this.staticVal.Code.Success) {
				orderItems.deploy.row.forEach((e) => {
					if (response1.data[e.property]) {
						e.default = response1.data[e.property];
					}
				});
			}
			this.items = this.items.concat(
				[{title: '订单', mainbody: {module: 'forms', binds: orderItems}}]
			);
			this.axios.post(this.orderLog, {type: 'INVOICE', regno: item.bookingid}).then(r => {
				if (r.code === this.staticVal.Code.Success) {
					this.tableProps.listData = r.data;
				}
			});
			this.items = this.items.concat(
				[{title: '日志', mainbody: {module: 'tables', binds: this.tableProps}}]
			);
		}
	}
}
