export default {
	data() {
		return {
			orderItems: {
				deploy: {
					row: [
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'invoicecode',
							connector: {
								props: {
									label: "发票代码",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'invoiceno',
							connector: {
								props: {
									label: "发票号码",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'payid',
							connector: {
								props: {
									label: "支付流水号",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'bookername',
							connector: {
								props: {
									label: "预订人姓名",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'tel',
							connector: {
								props: {
									label: "预订人手机号",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'amount',
							connector: {
								props: {
									label: "订单金额",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'ptypedesc',
							connector: {
								props: {
									label: "商品类型",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'amount',
							connector: {
								props: {
									label: "实际支付金额",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'istatus',
							connector: {
								props: {
									label: "发票状态",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'redextaxamount',
							connector: {
								props: {
									label: "对冲金额",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'extaxamount',
							connector: {
								props: {
									label: "发票金额",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'createtime',
							connector: {
								props: {
									label: "创建时间",
									readonly: true
								}
							}
						}
					]
				}
			}
		}
	}
}
