<template>
	<div>
		<common-table
				:itemScope="['status']"
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				:btnAction="true"
				ref="ct"
				pageTitle="发票管理"
				:customHtml="true"
				:btnActionEvent="btnActionEvent"
				@otherTableOperate="otherTableOperate"
		>
			<template v-slot:status="{ item }">
				<!-- 格式化订单状态 -->
				<formatter-field :status="item.status" field="payStatus"/>
			</template>
			<template v-slot:customHtml>
				<advanced-search
						:fixedConfig="fixedConfig"
						:othersConfig="othersConfig"
						@search="search"
						@advancedSearch="advancedSearch"
				/>
			</template>
		</common-table>
		<template v-if="dialog">
			<exhibition-data-box :width="936" :contentHeight="500" :dialog.sync="dialog" usageMode="tabs" :items="items"/>
		</template>
		<template v-if="invoiceDialog">
			<form-menu
					:center="true"
					:center_btn="true"
					:listDeploy="listDeploy"
					width="320px"
					height="520px"
					rightbtn_text="对冲"
					leftbtn_text="取消"
					:cancel_reset="true"
					@resetForm="invoiceDialog = false"
					:ridDivision="true"
					@validateSave="validateSave"
					:dialog.sync="invoiceDialog"
					title="发票对冲"/>
		</template>
	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/orderManage/invoiceManage.js';
import formMenu from "@/components/form-menu/form-menu.vue";
import advancedSearch from "@/components/advancedSearch/advancedSearch.vue";
import exhibitionDataBox from '@/components/exhibition-data-box/exhibition-data-box.vue';
import detailsOrder from '../common-order-js/details-invoice-order';
import detailsLog from '../common-order-js/details-log.js';
import orderDetailsTabs from '../common-order-js/invoice-details-tabs';
import formatterField from '../formatter.vue';

export default {
	// 发票管理
	components: {advancedSearch, exhibitionDataBox, formMenu, formatterField},
	mixins: [commonCurd, detailsOrder, detailsLog, orderDetailsTabs],
	data() {
		return {
			api,
			dialog: false,
			invoiceDialog: false,
			items: [],
			listDeploy: {
				row: [
					{
						subassembly: 'VTextField',
						cols: 12,
						property: 'invoicecode',
						connector: {
							props: {
								readonly: true,
								label: "发票代码"
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 12,
						property: 'invoiceno',
						connector: {
							props: {
								readonly: true,
								label: "发票号码"
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 12,
						property: 'sqlid',
						connector: {
							readonly: true,
							style: 'display: none'
						}
					},
					// {
					// 	subassembly: 'VTextField',
					// 	cols: 12,
					// 	property: 'name',
					// 	connector: {
					// 		props: {
					// 			label: "货物或应税劳务、服务名称"
					// 		}
					// 	}
					// },
					{
						subassembly: 'VTextField',
						cols: 12,
						property: 'amount',
						connector: {
							props: {
								readonly: true,
								label: "对冲金额"
							}
						}
					},
					// {
					// 	subassembly: 'VTextField',
					// 	cols: 12,
					// 	property: 'tax',
					// 	connector: {
					// 		props: {
					// 			label: "税额"
					// 		}
					// 	}
					// }
				]
			},
			btnActionEvent: [
				{
					event: 'getDetails',
					icon: 'chakan-fill',
					color: 'primary',
					tooltip: '详情'
				},
				{
					event: 'openInvoice',
					icon: 'chexiao',
					color: 'primary',
					hasDisableAttribute: true,
					tooltip: '发票对冲'
				}
			],
			headers: [
				{text: '主订单号', value: 'bookingid'},
				// {text: '支付订单号', value: 'payno'},
				{text: '预订人姓名', value: 'bookername'},
				{text: '预订人电话', value: 'tel'},
				// {text: '支付状态', value: 'status'},
				{text: '订单金额', value: 'amount'},
				// {text: '支付方式', value: 'paymethod'},
				{text: '商品类型', value: 'ptype'},
				{text: '发票代码', value: 'invoicecode'},
				{text: '发票号码', value: 'invoiceno'},
				{text: '发票金额', value: 'amount'},
				{text: '发票状态', value: 'istatus'},
				// {text: '操作人', value: 'handler'},
				{text: '创建时间', value: 'createtime'},
				{text: '操作', value: 'actions', width: '120px'},
			],
			fixedConfig: [
				// {type: 'input', label: '支付订单号', property: 'payno'},
				{type: 'input', label: '主订单号', property: 'bookingid'},
				// {type: 'input', label: '订单号/综合订单号', property: 'bookingid'}
			],
			othersConfig: [
				// {type: 'input', label: '预订人姓名/电话', property: 'bookername'},
				// {type: 'select', label: '选择支付状态', property: 'status', items: []},
				// {type: 'select', label: '选择支付方式', property: 'payment', items: []},
				// {type: 'select', label: '选择商品类型', property: 'ptype', items: []},
				{type: 'input', label: '输入发票代码', property: 'invoicecode'},
				// {type: 'select', label: '选择发票类型', property: 'invoicetype', items: []},
				{type: 'select', label: '选择发票状态', property: 'istatus', items: []},
				// {type: 'dateTime', label: '更新日期', property: 'handletime'}
			]
		}
	},
	methods: {
		advancedSearch() {
			this.$nextTick(() => {
				this.$refs.ct.getTableHeight();
			});
		},
		otherTableOperate(e, item) {
			if (e === this.btnActionEvent[0].event) {
				this.getOrderDetailsTabs(item);
			} else if (e === this.btnActionEvent[1].event) {
				// this.listDeploy.row[0].default = item.bookingid;
				this.listDeploy.row[0].default = item.invoicecode;
				this.listDeploy.row[1].default = item.invoiceno;
				this.listDeploy.row[2].default = item.sqlid;
				this.listDeploy.row[3].default = item.amount;
				this.invoiceDialog = true;
			}
		},
		validateSave(item, callback) {
			if (item.done) {
				this.axios.post(this.api.invoiceRed, item.form).then(r => {
					if (r.code === this.staticVal.Code.Success) {
						this.snackbar.success("操作成功");
						callback();
						this.list();
					} else {
						this.$refs.ct.$refs.formRef.load = false;
					}
				}).catch(()=> {
					this.$refs.ct.$refs.formRef.load = false;
				})
			}
		}
	},
	mounted() {
		// 初始化下拉框数据
		this.axios.post(this.select_data, {keyname: 'invoicestatus'}).then((res) => {
			if (res.code === this.staticVal.Code.Success) {
				this.othersConfig[1].items = res.data[0].values;
			}
		});
	},
	watch: {
		listData: {
			handler(data) {
				if (data) {
					data.records.forEach(item => {
						// 设置操作按钮的禁用状态
						item.hasDisableAttribute = item.istatus !== '已开';
					})
				}
			}
		}
	}
}
</script>

<style scoped lang="scss">

</style>
